export const getBannedCountriesFilter = () => {
  try {
    return (
      JSON.parse(process.env.REACT_APP_BANNED_COUNTRIES || "[]")
        ?.map((e) => `(NOT country_name:"${e}")`)
        .join(" AND ") || ""
    );
  } catch (err) {
    return "";
  }
};

export function formatLocation(location = {}) {
  return {
    locationId: location.id,
    cityName: location.name,
    stateId: location.state_id,
    stateCode: location.state_code,
    stateName: location.state_name,
    countryId: location.country_id,
    countryCode: location.country_code,
    countryName: location.country_name,
    latitude: location.latitude,
    longitude: location.longitude,
    wikiDataId: location.wikiDataId,
  };
}

export function unFormatLocation(location) {
  return {
    id: location.locationId,
    name: location.cityName,
    state_id: location.stateId,
    state_code: location.stateCode,
    state_name: location.stateName,
    country_id: location.countryId,
    country_code: location.countryCode,
    country_name: location.countryName,
    latitude: location.latitude,
    longitude: location.longitude,
    wikiDataId: location.wikiDataId,
  };
}
