import { TextField } from "@aws-amplify/ui-react";

import { readOnlyFields, integerFields } from "../../constants";

const FieldsSection = ({
  entity,
  heading,
  fields = {},
  updateData,
  pairedFieldsKey,
  subheading,
  hideDottedBorder,
  className = "mt-10",
}) => {
  const handleChange = (event) => {
    updateData(event.target.id, event.target.value, pairedFieldsKey);
  };

  const getPropertyName = (path) => {
    const properties = path.split(".");

    return properties[properties.length - 1];
  };

  return (
    <section className={className}>
      <h2 className="font-bold">{heading}</h2>
      {subheading && <h4 className="italic text-sm my-2">{subheading}</h4>}
      <div className={!hideDottedBorder ? "dashed-section" : ""}>
        {Object.entries(fields).map(([key, value]) => (
          <div className="flex flex-nowrap items-center my-2" key={key}>
            <label htmlFor={key} className="basis-1/3 shrink-0">
              {getPropertyName(key)}
            </label>
            <div className="grow">
              {typeof value === "object" && value !== null ? (
                value
              ) : (
                <TextField
                  type={integerFields[entity].includes(key) ? "number" : "text"}
                  id={key}
                  value={value || ""}
                  onChange={handleChange}
                  isDisabled={readOnlyFields[entity].includes(key)}
                  labelHidden={true}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FieldsSection;
