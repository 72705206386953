import { TextAreaField, SelectField } from "@aws-amplify/ui-react";

import FieldsSection from "../FieldsSection";
import SocialLinks from "../SocialLinks";
import OtherLinks from "../OtherLinks";
import { selectOptions } from "../../constants";
import Location from "../Location";

const CommonFields = ({ user, updateUser, handlePairedFieldsChange }) => (
  <>
    <FieldsSection
      entity="user"
      fields={{
        given_name: user.given_name,
        family_name: user.family_name,
        email: user.email,
        username: user.username,
        identity_username: user.identity_username,
      }}
      updateData={updateUser}
    />
    <FieldsSection
      heading="Profile"
      entity="user"
      fields={{
        userType: (
          <SelectField
            value={user.userType}
            onChange={(event) => updateUser("userType", event.target.value)}
            placeholder="null"
            options={selectOptions.userType}
            labelHidden
          />
        ),
        status: (
          <SelectField
            value={user.status}
            onChange={(event) => updateUser("status", event.target.value)}
            placeholder="null"
            options={selectOptions.userStatus}
            labelHidden
          />
        ),
        profileCompletion: user.profileCompletion,
        tagline: user.tagline,
        bio: (
          <TextAreaField
            value={user.bio || ""}
            onChange={(event) => updateUser("bio", event.target.value)}
            resize="vertical"
            labelHidden={true}
          />
        ),
      }}
      updateData={updateUser}
    />
    <SocialLinks
      links={user.socialLinks}
      userType={user.userType}
      updateData={updateUser}
    />
    <OtherLinks links={user.otherLinks} updateData={updateUser} />

    <Location
      entity="user"
      location={user.location}
      updateData={updateUser}
      handlePairedFieldsChange={handlePairedFieldsChange}
    />
  </>
);

export default CommonFields;
