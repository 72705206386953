import FieldsSection from "../FieldsSection";
import SearchLocation from "./molecules/SearchLocaton";
import { formatLocation } from "../../helpers/searchLocation";

const Location = ({
  entity,
  location,
  fieldName = "location",
  updateData,
  handlePairedFieldsChange,
}) => {
  return (
    <div className="mt-10">
      <h2 className="font-bold">Location</h2>

      <div className="dashed-section">
        <div>
          <h2 className="font-bold">Find Location</h2>
          <SearchLocation
            setLocation={(location) => {
              updateData(fieldName, formatLocation(location));
            }}
            clearOnSelect
          />
        </div>

        <FieldsSection
          hideDottedBorder
          entity={entity}
          fields={{
            [`${fieldName}.cityName`]: location?.cityName,
            [`${fieldName}.countryCode`]: location?.countryCode,
            [`${fieldName}.countryId`]: location?.countryId,
            [`${fieldName}.countryName`]: location?.countryName,
            [`${fieldName}.latitude`]: location?.latitude,
            [`${fieldName}.locationId`]: location?.locationId,
            [`${fieldName}.longitude`]: location?.longitude,
            [`${fieldName}.stateCode`]: location?.stateCode,
            [`${fieldName}.stateId`]: location?.stateId,
            [`${fieldName}.stateName`]: location?.stateName,
            [`${fieldName}.wikiDataId`]: location?.wikiDataId,
          }}
          updateData={handlePairedFieldsChange}
          pairedFieldsKey={fieldName}
        />
      </div>
    </div>
  );
};

export default Location;
